import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard, WizardHero } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './IntroStep.scss';

function IntroStep({ localizedContent: { redeemCodeWizard: content } }) {
  const { setNextStepDisabled } = useWizard();

  useEffect(() => {
    setNextStepDisabled(false);
  }, []);

  return (
    <div data-testid="REDEEM_CODE_WIZARD_STEP_1" className={styles.stepContainer}>
      <WizardHero
        heading={{ text: content.redeem_code_intro_step_title }}
        subHeading={content.redeem_code_intro_step_subtitle}
        variant="introduction"
        className={styles.wizardHero}
        image={{
          src: HubIllustrationConstants.DASHBOARD,
          alt: HubIllustrationAltText.DASHBOARD
        }}
        content={
          <ol>
            <li>{content.redeem_code_intro_step_content_1}</li>
            <li>{content.redeem_code_intro_step_content_2}</li>
            <li>{content.redeem_code_intro_step_content_3}</li>
          </ol>
        }
      />
    </div>
  );
}

IntroStep.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodeWizard'))(IntroStep);
