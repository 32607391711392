import React from 'react';
import PropTypes from 'prop-types';

import { useWizard, Footer } from '@oup/shared-front-end';
import { ICON_LEFT } from '@oup/shared-front-end/svg/oup';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';

function RedeemCodeWizardFooter({ localizedContent: { redeemCodeWizard: content } }) {
  const { step, nextStep, prevStep, nextStepDisabled } = useWizard();

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={step !== 1 ? content.next : content.check_code}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        dataAttributes: {
          testid: 'REDEEM_CODE_WIZARD_NEXT_BUTTON'
        }
      }}
      secondaryButtonLabel={step !== 0 ? content.back : ''}
      secondaryButtonAttributes={{
        dataAttributes: {
          testid: 'REDEEM_CODE_WIZARD_BACK_BUTTON'
        },
        icon: {
          placement: 'left',
          component: <ICON_LEFT />
        }
      }}
      secondaryButtonAction={prevStep}
    />
  );
}

RedeemCodeWizardFooter.propTypes = { localizedContent: PropTypes.object.isRequired };

export default compose(withLocalizedContent('redeemCodeWizard'))(RedeemCodeWizardFooter);
