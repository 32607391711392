import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import styles from './HubFooter.scss';
import breakpoints from '../../globals/breakpoints';

import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import { useEltBranding } from '../../utils/platform';
import { displayHeaderOrFooter } from '../../services/cptHelperMethods.js';

const enableSocialLinks = false; // switched off for launch
const showEltProducts = useEltBranding();

const listOneKeyRegex = /^linkListOne_listItem\d+_text$/;
const listTwoKeyRegex = /^linkListTwo_listItem\d+_text$/;
const listThreeKeyRegex = /^linkListThree_listItem\d+_text$/;

function HubFooter({
  hubFooterContent,
  hideUpperFooter,
  breakpoint,
  scrollReachBottom,
  darkLayout,
  showRecaptchaFooter = false
}) {
  const CMS = hubFooterContent;

  // Display all the time on mobile && for desktop only on specific pages (hompage, register, etc)
  const showTopperWrapper = !hideUpperFooter || breakpoint === breakpoints.XXS;
  // Show content of upper footer
  const showTopperContent = !hideUpperFooter;

  const cmsItemsListOne = Object.keys(CMS)
    .filter(key => listOneKeyRegex.test(key))
    .sort();
  const cmsItemsListTwo = Object.keys(CMS)
    .filter(key => listTwoKeyRegex.test(key))
    .sort();
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const [displayFooter, setDisplayFooter] = useState(true);
  const location = useLocation();

  useEffect(() => displayHeaderOrFooter(location, setDisplayFooter), [location]);

  return (
    displayFooter && (
      <footer
        className={`${styles.siteFooter} ${styles.footerRestyling} ${darkLayout &&
          styles.darkBackground} ${scrollReachBottom && styles.fixed}`}
      >
        {enableSocialLinks && (
          <div className={styles.socialStrip}>
            <div className="grid">
              <div className="row">
                <div className="col">
                  <ul>
                    <li>
                      {CMS.twitter_icon_link && (
                        <a href={CMS.twitter_icon_link} target="_blank" rel="noreferrer">
                          <SVGIcon glyph={GLYPHS.ICON_TWITTER_LOGO} />
                          <span className="a11y-hide">
                            {CMS.twitter_link_text} {CMS.ally_opens_in_new_tab}
                          </span>
                        </a>
                      )}
                    </li>
                    <li>
                      {CMS.facebook_icon_link && (
                        <a href={CMS.facebook_icon_link} target="_blank" rel="noreferrer">
                          <SVGIcon glyph={GLYPHS.ICON_FACEBOOK_LOGO} />
                          <span className="a11y-hide">
                            {CMS.facebook_link_text} {CMS.ally_opens_in_new_tab}
                          </span>
                        </a>
                      )}
                    </li>
                    <li>
                      {CMS.youtube_icon_link && (
                        <a href={CMS.youtube_icon_link} target="_blank" rel="noreferrer">
                          <SVGIcon glyph={GLYPHS.ICON_YOUTUBE_LOGO} />
                          <span className="a11y-hide">
                            {CMS.youtube_link_text} {CMS.ally_opens_in_new_tab}
                          </span>
                        </a>
                      )}
                    </li>
                    <li>
                      {CMS.linkedin_icon_link && (
                        <a href={CMS.linkedin_icon_link} target="_blank" rel="noreferrer">
                          <SVGIcon glyph={GLYPHS.ICON_LINKEDIN_LOGO} />
                          <span className="a11y-hide">
                            {CMS.linkedin_link_text} {CMS.ally_opens_in_new_tab}
                          </span>
                        </a>
                      )}
                    </li>
                    <li>
                      {CMS.wordpress_icon_link && (
                        <a href={CMS.wordpress_icon_link} target="_blank" rel="noreferrer">
                          <SVGIcon glyph={GLYPHS.ICON_WORDPRESS_LOGO} />
                          <span className="a11y-hide">
                            {CMS.wordpress_link_text} {CMS.ally_opens_in_new_tab}
                          </span>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {showEltProducts && showTopperWrapper && (
          <div className={classnames('grid', styles.topSection)}>
            <div className="row">
              {showTopperContent && (
                <div className={classnames('col sm-show sm3 md2', styles.linkList)}>
                  <div id="linkListOne_header">{CMS.linkListOne_header}</div>
                  <ul aria-labelledby="linkListOne_header">
                    {cmsItemsListOne.map((key, index) => (
                      <li key={key}>
                        <a
                          href={`${CMS[key.replace('_text', '_target')]}?${currentTimestamp}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="a11y-hide">{`${index + 1} of ${cmsItemsListOne.length} `}</span>
                          {CMS[key]}
                          <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {showTopperContent && (
                <div className={classnames('col sm-show sm3 md2', styles.linkList)}>
                  <div id="linkListTwo_header">{CMS.linkListTwo_header}</div>
                  <ul aria-labelledby="linkListTwo_header">
                    {cmsItemsListTwo.map((key, index) => (
                      <li key={key}>
                        <a href={CMS[key.replace('_text', '_target')]} target="_blank" rel="noreferrer">
                          <span className="a11y-hide">{`${index + 1} of ${cmsItemsListTwo.length} `}</span>
                          {CMS[key]}
                          <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {showTopperContent && (
                <div className={classnames('col sm-show sm3 md2', styles.linkList)}>
                  <div>{CMS.linkListThree_header}</div>
                  <ul>
                    {Object.keys(CMS)
                      .filter(key => listThreeKeyRegex.test(key))
                      .sort()
                      .map(key => (
                        <li key={key}>
                          <a href={CMS[key.replace('_text', '_target')]} target="_blank" rel="noreferrer">
                            <span className="a11y-hide">({CMS.linkListThree_header})</span> {CMS[key]}
                            <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              <div className={classnames('col sm3 md6', styles.logoContainer)}>
                {showTopperContent && <p className={styles.footer_text}>{CMS.footer_text}</p>}
                <SVGIcon glyph={GLYPHS.OUP_LOGO_BLUE} />
              </div>
            </div>
          </div>
        )}
        {/* eslint-disable-next-line react/no-danger */}
        {showRecaptchaFooter && (
          // eslint-disable-next-line react/no-danger
          <p className={styles.Recaptcha} dangerouslySetInnerHTML={{ __html: CMS.recaptcha_html }} />
        )}
        <div className={styles.legalStrip} id="legalStrip">
          <div className="grid">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <a href={CMS.helpAndSupport_target} target="_blank" rel="noreferrer">
                      {CMS.helpAndSupport_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  <li>
                    <a href={CMS.privacyLink_target} target="_blank" rel="noreferrer">
                      {CMS.privacyLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  <li>
                    <a href={CMS.childrenPrivacyLink_target} target="_blank" rel="noreferrer">
                      {CMS.childrenPrivacyLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  <li>
                    <a href={CMS.cookieLink_target} target="_blank" rel="noreferrer">
                      {CMS.cookieLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  <li>
                    <a href={CMS.allyLink_target} target="_blank" rel="noreferrer">
                      {CMS.allyLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  {CMS.legalLink_text && (
                    <li>
                      <a href={CMS.legalLink_target} target="_blank" rel="noreferrer">
                        {CMS.legalLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                      </a>
                    </li>
                  )}
                  {CMS.termsLink_text && (
                    <li>
                      <a href={`${CMS.termsLink_target}?${currentTimestamp}`} target="_blank" rel="noreferrer">
                        {CMS.termsLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                      </a>
                    </li>
                  )}
                  <li className={styles.copy}>
                    &copy; {new Date().getUTCFullYear()} {CMS.copyRight}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}

HubFooter.propTypes = {
  hubFooterContent: PropTypes.object.isRequired,
  hideUpperFooter: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
  scrollReachBottom: PropTypes.bool,
  darkLayout: PropTypes.bool.isRequired,
  showRecaptchaFooter: PropTypes.bool
};

export default HubFooter;
