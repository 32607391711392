import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useWizard, TextInput, Heading } from '@oup/shared-front-end';

import { setFirstName, setLastName } from '../../../redux/reducers/registration/registration.reducer.js';
import appSettings from '../../../globals/appSettings.js';
import styles from './FirstLastNameStep.scss';

function FirstAndLastNameStep({ content, firstNameValue, lastNameValue, setFirstNameAction, setLastNameAction }) {
  const { setNextStepDisabled } = useWizard();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameFieldState, setFirstNameFieldState] = useState('default');
  const [lastNameFieldState, setLastNameFieldState] = useState('default');

  useEffect(() => {
    if (firstNameFieldState === 'valid' && lastNameFieldState === 'valid') {
      setNextStepDisabled(false);
    } else {
      setNextStepDisabled(true);
    }
  }, [firstNameFieldState, lastNameFieldState]);

  useEffect(() => {
    setNextStepDisabled(!(firstNameValue && lastNameValue));
  }, []);

  const validateName = (value = '') => {
    const trimmedValue = value.trim();
    const invalidName = trimmedValue.length <= 1;
    const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(trimmedValue);

    return { invalidName, invalidCharacters };
  };

  return (
    <div data-testid="REGISTER_WIZARD_NAME_STEP" className={styles.firstLastNameStepContainer}>
      <div className={styles.container}>
        <Heading text={content.wizard_first_step_title} size="medium" className={styles.heading} />

        <TextInput
          id="firstName"
          name="firstName"
          required
          label={content.first_name_label}
          value={firstNameValue}
          onBlur={({ target: { value } }) => {
            const { invalidName, invalidCharacters } = validateName(value);

            if (invalidName) {
              setFirstNameError(content.forename_required_text);
              setFirstNameFieldState('invalid');
              return;
            }

            if (invalidCharacters) {
              setFirstNameError(content.invalid_name_error);
              setFirstNameFieldState('invalid');
              return;
            }

            if (!invalidName && !invalidCharacters) {
              setFirstNameError('');
              setFirstNameFieldState('valid');
            }
          }}
          onChange={({ target: { value } }) => {
            setFirstNameAction(value);
          }}
          state={firstNameFieldState}
          validationMessage={firstNameFieldState === 'valid' ? '' : firstNameError}
          dataAttributes={{ testId: 'WIZARD_REGISTER_FIRST_NAME' }}
        />
        <TextInput
          id="lastName"
          name="lastName"
          required
          label={content.last_name_label}
          subLabel={content.required_sub_label}
          value={lastNameValue}
          onBlur={({ target: { value } }) => {
            const { invalidName, invalidCharacters } = validateName(value);

            if (invalidName) {
              setLastNameError(content.surname_required_text);
              setLastNameFieldState('invalid');
              return;
            }

            if (invalidCharacters) {
              setLastNameError(content.invalid_name_error);
              setLastNameFieldState('invalid');
              return;
            }

            if (!invalidName && !invalidCharacters) {
              setLastNameError('');
              setLastNameFieldState('valid');
            }
          }}
          onChange={({ target: { value } }) => {
            setLastNameAction(value);
          }}
          state={lastNameFieldState}
          validationMessage={lastNameFieldState === 'valid' ? '' : lastNameError}
          dataAttributes={{ testId: 'WIZARD_REGISTER_LAST_NAME' }}
        />
      </div>
    </div>
  );
}

FirstAndLastNameStep.propTypes = {
  content: PropTypes.object.isRequired,
  firstNameValue: PropTypes.string.isRequired,
  lastNameValue: PropTypes.string.isRequired,
  setFirstNameAction: PropTypes.func.isRequired,
  setLastNameAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    firstNameValue: state.registration.firstNameValue,
    lastNameValue: state.registration.lastNameValue
  }),
  {
    setFirstNameAction: setFirstName,
    setLastNameAction: setLastName
  }
)(FirstAndLastNameStep);
