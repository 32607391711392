import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useWizard, InformationBox, TextInput, TextLink, Heading } from '@oup/shared-front-end';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import appSettings from '../../globals/appSettings.js';
import styles from './EnterAccessCodeStep.scss';

import { validateAccessCodeRequest, validateAccessCodeRequestReset } from '../../redux/actions/validateAccessCode.js';

const getLicenceSource = licenceValue => {
  const vstPrefixes = ['A', 'S', 'T'];
  const isVstLicence = vstPrefixes.includes(licenceValue.charAt(0));
  return isVstLicence ? 'VST' : 'EPS';
};

function EnterAccessCodeStep({
  localizedContent: { redeemCodeWizard: content, validateAccessCode: contentValidateAccessCode },
  valid,
  googleReCaptchaProps,
  validateAccessCodeAction,
  validateAccessCodeResetAction,
  error
}) {
  const { setNextStepDisabled, stepHandler, nextStep } = useWizard();
  const [accessCodeValue, setAccessCodeValue] = useState('');
  const [accessCodeError, setAccessCodeError] = useState('');
  const [accessCodeFieldState, setAccessCodeFieldState] = useState('default');

  const setAccessCode = accessCode => {
    const isVstCode = appSettings.ACCESS_CODE_VST_REGEX.test(accessCode);
    const formattedCode = isVstCode
      ? accessCode.replace(/([ASTast]{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
      : accessCode.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');

    setAccessCodeValue(formattedCode);
    setAccessCodeError('');
    setAccessCodeFieldState('default');
  };

  const validateAccessCode = async code => {
    const invalidAccessCodeLength = code.length < 12;
    const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(code);
    const validFormat = appSettings.ACCESS_CODE_REGEX.test(code) || appSettings.ACCESS_CODE_VST_REGEX.test(code);

    if (invalidAccessCodeLength) {
      return contentValidateAccessCode.access_code_max_length;
    }

    if (invalidCharacters) {
      return contentValidateAccessCode.access_code_error_invalid_characters;
    }

    if (!validFormat) {
      return contentValidateAccessCode.access_code_incorrect_format;
    }

    return '';
  };

  useEffect(() => {
    setNextStepDisabled(!accessCodeValue);
  }, [accessCodeValue]);

  useEffect(() => {
    if (valid && accessCodeValue !== '') {
      setAccessCodeFieldState('default');
      setAccessCodeError('');
      validateAccessCodeResetAction();
      nextStep();
    }
  }, [valid]);

  useEffect(() => {
    if (error) {
      setAccessCodeFieldState('invalid');
      setAccessCodeError(contentValidateAccessCode[error.code] || contentValidateAccessCode.access_code_error);
      setNextStepDisabled(true);
    } else {
      setAccessCodeFieldState('default');
      setAccessCodeError('');
    }
  }, [error]);

  stepHandler(async () => {
    if (valid) {
      return;
    }

    const validationError = await validateAccessCode(accessCodeValue);
    if (validationError === '' && typeof googleReCaptchaProps.executeRecaptcha === 'function') {
      await validateAccessCodeAction({
        accessCode: accessCodeValue,
        reCAPTCHAToken: await googleReCaptchaProps.executeRecaptcha(APP_CONSTANTS.RECAPTCHA_ACTIONS.REDEEM_CODE),
        source: getLicenceSource(accessCodeValue)
      });
    } else {
      setAccessCodeError(validationError);
      setAccessCodeFieldState('invalid');
      setNextStepDisabled(true);
    }
    throw new Error();
  });

  return (
    <div data-testid="REDEEM_CODE_WIZARD_STEP_2" className={styles.stepContainer}>
      <div className={styles.enterCodeContent}>
        <Heading text={content.check_your_access_code} size="medium" className={styles.heading} />
        <TextInput
          id="accessCode"
          name="accessCode"
          maxLength={14}
          label={content.enter_access_code}
          value={accessCodeValue}
          onChange={e => setAccessCode(e.target.value)}
          state={accessCodeFieldState}
          validationMessage={accessCodeError}
          dataAttributes={{ testId: 'REDEEM_CODE_WIZARD_ACCESS_CODE' }}
        />
      </div>

      <div className={styles.rightColumnContainer}>
        <InformationBox title={content.about_codes}>
          <div>
            <p>{content.about_codes_content_1}</p>
            <p>{content.about_codes_content_2}</p>
            <p>{content.about_codes_content_3}</p>
            <div className={styles.linksContainer}>
              <span>
                <TextLink to={content.need_help_with_your_code_link} target="_blank">
                  {content.need_help_with_your_code}
                </TextLink>
              </span>
              <span>
                <TextLink to={content.buy_a_code_link} target="_blank">
                  {content.buy_a_code}
                </TextLink>
              </span>
            </div>
          </div>
        </InformationBox>
      </div>
    </div>
  );
}

EnterAccessCodeStep.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  valid: PropTypes.bool,
  googleReCaptchaProps: PropTypes.object.isRequired,
  validateAccessCodeAction: PropTypes.func.isRequired,
  validateAccessCodeResetAction: PropTypes.func.isRequired,
  error: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.shape({
      code: PropTypes.string,
      activationDate: PropTypes.string
    })
  )
};

export default compose(
  withLocalizedContent('redeemCodeWizard'),
  withLocalizedContent('validateAccessCode'),
  connect(
    state => ({
      error: state.validateAccessCode.error,
      valid: state.validateAccessCode.valid
    }),
    {
      validateAccessCodeAction: validateAccessCodeRequest,
      validateAccessCodeResetAction: validateAccessCodeRequestReset
    }
  )
)(withGoogleReCaptcha(EnterAccessCodeStep));
