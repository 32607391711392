import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import mountLiveChat, { unmountLiveChat } from '../../utils/chat/liveChat';
import RedeemCodeWizard from '../../components/RedeemCodeWizard/RedeemCodeWizard.js';

function RedeemCodeYoungLearnerPage({ localizedContent: { redeemCodePage: content } }) {
  useEffect(() => {
    mountLiveChat();

    return () => {
      unmountLiveChat();
    };
  }, []);

  return (
    <div>
      <Helmet title={content.page_title} />
      <RedeemCodeWizard isOpen />
    </div>
  );
}

RedeemCodeYoungLearnerPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodePage'))(RedeemCodeYoungLearnerPage);
