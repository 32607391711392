import React from 'react';
import PropTypes from 'prop-types';

import { Wizard, SiteHeader, Button } from '@oup/shared-front-end';
import { ICON_HELP_CIRCLE } from '@oup/shared-front-end/svg/oup';

import RegisterWizardFooter from './RegisterWizardFooter.js';

import withLocalizedContent from '../../language/withLocalizedContent';
import { HubLayoutConstants, USER_TYPES, USER_AGE } from '../../globals/hubConstants.js';
import useMediaQuery from '../../utils/mediaQuery.js';
import FirstAndLastNameStep from './FirstLastNameStep/FirstLastNameStep.js';
import CaptureEmailStep from './CaptureEmailStep/CaptureEmailStep.js';
import CapturePasswordStep from './CapturePassword/CapturePasswordStep.js';
import CaptureTermsAndConditionsStep from './CaptureTermsAndConditionsStep/CaptureTermsAndConditionsStep.js';
import RegistrationComplete from './RegistrationComplete/RegistrationComplete.js';

import styles from './RegisterWizard.scss';

function RegisterWizard({
  isOpen,
  localizedContent: { registrationPage, registrationPageStudentOverSeventeen, registrationPageParentYLStudent }
}) {
  const isMobile = useMediaQuery('(max-width: 920px)');

  const getContent = () => {
    const userType = localStorage.getItem('userType');
    const userAge = localStorage.getItem('userAge');

    if (userType === USER_TYPES.STUDENT && userAge === USER_AGE.OVER_17) {
      return registrationPageStudentOverSeventeen;
    }
    if (userType === USER_TYPES.PARENT && userAge === USER_AGE.UNDER_12) {
      return registrationPageParentYLStudent;
    }
    return registrationPage;
  };

  const content = getContent();

  return isOpen ? (
    <div data-testid="ONBOARDING_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            buttons={[
              {
                type: Button,
                props: {
                  variant: 'filled',
                  icon: { component: <ICON_HELP_CIRCLE className={styles.helpIconLoggedOut} /> },
                  text: !isMobile ? content.support_heading_text : '',
                  onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank'),
                  className: !isMobile ? styles.helpButtonLarge : styles.helpButtonSmall
                }
              }
            ]}
            loggedIn={false}
            secondaryText={content.secondary_text}
          />
        }
        footer={<RegisterWizardFooter content={content} />}
      >
        <FirstAndLastNameStep content={content} />
        <CaptureEmailStep content={content} />
        <CapturePasswordStep content={content} />
        <CaptureTermsAndConditionsStep content={content} />
        <RegistrationComplete content={content} />
      </Wizard>
    </div>
  ) : null;
}

RegisterWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent(
  'registrationPage',
  'registrationPageStudentOverSeventeen',
  'registrationPageParentYLStudent'
)(RegisterWizard);
