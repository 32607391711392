import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import { ProgressBar, ValidationMessage, TextLink } from '@oup/shared-front-end';
import { SidePanel } from '../../components';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer';
import PopoutActionFooter from '../../components/PopoutActionFooter/PopoutActionFooter';
// Constants
import {
  getLimitTypeReached,
  limitTypes,
  maximumRecommendedLimits
} from '../../structure/HubDashboardLayout/Utils/getOrgUsageLimits.js';
// Style
import styles from './OrgUsagePanel.scss';

function OrgUsagePanel({
  isOpen,
  handleClosePanel,
  content,
  orgName,
  noOfClasses = 0,
  noOfUsers = 0,
  learnerCount = 0,
  teacherCount = 0,
  teacherAdminCount = 0,
  orgAdminCount = 0
}) {
  const limitTypeReached = getLimitTypeReached(noOfClasses, noOfUsers);

  const getValidationMessage = () => {
    if (limitTypeReached === limitTypes.NONE) return null;

    let limitTypeText = '';
    if (limitTypeReached === limitTypes.USERS) {
      limitTypeText = content.users;
    } else if (limitTypeReached === limitTypes.CLASSES) {
      limitTypeText = content.classes;
    } else {
      limitTypeText = `${content.users} and ${content.classes}`;
    }

    return (
      <ValidationMessage state="warning">
        <p>{content.organization_usage_warning_message.replace('{limit type}', limitTypeText)}</p>
        <div className={styles.linkContainer}>
          <TextLink to={content.learn_more_about_organization_limits_link} target="_blank" rel="noreferrer">
            {content.learn_more_about_organization_limits}
          </TextLink>
        </div>
      </ValidationMessage>
    );
  };

  const getProgressBar = (currentNumberText, maximumRecommendedText, value, max, isWarning) => (
    <div className={styles.progressContainer}>
      <p>
        <span>{currentNumberText}</span>
        <span>{maximumRecommendedText}</span>
      </p>
      <ProgressBar
        value={value}
        max={max}
        className={`${styles.roundedProgressBar} ${isWarning ? styles.warning : ''}`}
      />
    </div>
  );

  const renderContent = () => (
    <div className={styles.contentContainer}>
      {getValidationMessage()}

      {getProgressBar(
        `${noOfUsers} ${content.users}`,
        content.maximum_recommended_users,
        noOfUsers,
        maximumRecommendedLimits.USERS,
        limitTypeReached === limitTypes.USERS || limitTypeReached === limitTypes.USERS_AND_CLASSES
      )}

      <div className={styles.userCountContainer}>
        <div>
          <span>{content.students}</span>
          <span>{learnerCount}</span>
        </div>
        <div>
          <span>{content.teachers}</span>
          <span>{teacherCount}</span>
        </div>
        <div>
          <span>{content.class_administrators}</span>
          <span>{teacherAdminCount}</span>
        </div>
        <div>
          <span>{content.organization_administrators}</span>
          <span>{orgAdminCount}</span>
        </div>
      </div>

      {getProgressBar(
        `${noOfClasses} ${content.classes}`,
        content.maximum_recommended_classes,
        noOfClasses,
        maximumRecommendedLimits.CLASSES,
        limitTypeReached === limitTypes.CLASSES || limitTypeReached === limitTypes.USERS_AND_CLASSES
      )}
    </div>
  );

  return (
    <SidePanel
      id="OrgUsagePanel"
      ariaLabel={content.org_usage_panel_aria_label}
      isOpen={isOpen}
      onClose={handleClosePanel}
    >
      <ScrollContainer
        headerContent={
          <div className={styles.headerContainer}>
            <h2>{content.organization_usage}</h2>
            <p>{orgName}</p>
          </div>
        }
        footerContent={
          <PopoutActionFooter
            primaryButtonAction={handleClosePanel}
            primaryButtonText={content.done_button}
            primaryButtonTestHook="org-usage-done"
          />
        }
      >
        {renderContent()}
      </ScrollContainer>
    </SidePanel>
  );
}

OrgUsagePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClosePanel: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  orgName: PropTypes.string.isRequired,
  noOfClasses: PropTypes.string.isRequired,
  noOfUsers: PropTypes.string.isRequired,
  learnerCount: PropTypes.number,
  teacherCount: PropTypes.number,
  teacherAdminCount: PropTypes.number,
  orgAdminCount: PropTypes.number
};

export default connect(
  ({ orgUsage = {} }) => ({
    noOfClasses: orgUsage.classesCount,
    noOfUsers: orgUsage.activeUserCount,
    learnerCount: orgUsage.learnerCount,
    teacherCount: orgUsage.teacherCount,
    teacherAdminCount: orgUsage.teacherAdminCount,
    orgAdminCount: orgUsage.orgAdminCount
  }),
  null
)(OrgUsagePanel);
