import React from 'react';
import PropTypes from 'prop-types';
import { Wizard, SiteHeader } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import RedeemCodeWizardFooter from './RedeemCodeWizardFooter';
import { HubLayoutConstants } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';
import IntroStep from './IntroStep';
import EnterAccessCodeStep from './EnterAccessCodeStep';
import RegisterOrSignInStep from './RegisterOrSignInStep';

function RedeemCodeWizard({ localizedContent: { redeemCodeWizard: content }, isOpen }) {
  return isOpen ? (
    <div data-testid="REDEEM_CODE_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            helpButton={{
              text: content.help_and_support_button_text,
              onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank')
            }}
            secondaryText={content.oxford_english_hub}
          />
        }
        footer={<RedeemCodeWizardFooter />}
      >
        <IntroStep />
        <EnterAccessCodeStep />
        <RegisterOrSignInStep />
      </Wizard>
    </div>
  ) : null;
}

RedeemCodeWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodeWizard'))(RedeemCodeWizard);
