import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard, Heading, TextInput } from '@oup/shared-front-end';
import { ICON_EYE_OFF, ICON_EYE_ON } from '@oup/shared-front-end/svg/oup';
import styles from './CapturePasswordStep.scss';
import appSettings from '../../../globals/appSettings.js';
import { setPassword } from '../../../redux/reducers/registration/registration.reducer.js';

function CapturePasswordStep({ content, passwordValue, setPasswordAction, isYoungLearner }) {
  const { setNextStepDisabled } = useWizard();
  const [passwordError, setPasswordError] = useState('');
  const [passwordFieldState, setPasswordFieldState] = useState('default');
  const [inputType, setInputType] = useState('password');

  useEffect(() => {
    setNextStepDisabled(passwordFieldState !== 'valid');
  }, [passwordFieldState]);

  const validatePassword = (value = '') => {
    const trimmedValue = value.trim();
    const invalidName = trimmedValue.length <= 1;
    const invalidCharacters = !appSettings.PASSWORD_REGEX.test(trimmedValue);

    return { invalidName, invalidCharacters };
  };

  const togglePasswordVisibility = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <div data-testid="REGISTER_WIZARD_PASSWORD_STEP" className={styles.stepContainer}>
      <div>
        <Heading className={styles.heading} text={content.teacher_wizard_password_step_title} size="medium" />
        {isYoungLearner && <p className={styles.subHeading}>{content.yl_wizard_password_step_subtitle}</p>}
      </div>
      <TextInput
        id="password"
        name="password"
        type={inputType}
        button={{
          action: togglePasswordVisibility,
          icon: inputType === 'password' ? <ICON_EYE_OFF /> : <ICON_EYE_ON />,
          type: 'button'
        }}
        viewPasswordButton
        required
        label={content.password}
        value={passwordValue}
        onChange={({ target: { value } }) => {
          setPasswordAction(value);
          const { invalidName, invalidCharacters } = validatePassword(value);

          if (invalidName) {
            setPasswordError(content.password_required_text);
            setPasswordFieldState('invalid');
            return;
          }

          if (invalidCharacters) {
            setPasswordError(content.password_invalid_text);
            setPasswordFieldState('invalid');
            return;
          }

          if (!invalidName && !invalidCharacters) {
            setPasswordError('');
            setPasswordFieldState('valid');
          }
        }}
        state={passwordFieldState}
        validationMessage={passwordFieldState === 'valid' ? '' : passwordError}
        dataAttributes={{ testId: 'WIZARD_REGISTER_PASSWORD' }}
      />
      <div>
        <h3>{content.password_rules_title}</h3>
        <ul>
          <li>{content.password_rule_1}</li>
          <li>{content.password_rule_2}</li>
          <li>{content.password_rule_3}</li>
          <li>{content.password_rule_4}</li>
        </ul>
      </div>
    </div>
  );
}

CapturePasswordStep.propTypes = {
  content: PropTypes.object.isRequired,
  passwordValue: PropTypes.string.isRequired,
  setPasswordAction: PropTypes.func.isRequired,
  isYoungLearner: PropTypes.bool
};

export default connect(
  ({ registration }) => ({
    passwordValue: registration.passwordValue,
    isYoungLearner: window.location.href.includes('young-learner')
  }),
  {
    setPasswordAction: setPassword
  }
)(CapturePasswordStep);
